module services {
    export module documentManagement {
        export class documentRepositoryService implements interfaces.documentManagement.IDocumentRepositoryService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDocumentRepository(): ng.resource.IResourceClass<interfaces.documentManagement.IDocumentRepository> {

                return this.$resource<interfaces.documentManagement.IDocumentRepository>(this.ENV.DSP_URL + "DocumentRepository/GetDocumentRepository", {
                    docId: '@docId',
                    referenceNumber: '@referenceNumber',
                    systemType: '@systemType',
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            method: 'GET',
                            isArray: false
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "DocumentRepository/Save");
            }

        
        }
    }
    angular.module("app").service("documentRepositoryService", services.documentManagement.documentRepositoryService);
}